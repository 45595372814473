import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlTimePickerModel, FormControlMonthSeasonModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import moment, { Moment } from 'moment';
import { TimePlanRuleType } from '@/model/enum';

const TIME_PLAN_RULE_TYPE_OPTIONS = [
    { name: '天', value: TimePlanRuleType.DAY },
    { name: '周', value: TimePlanRuleType.WEEK },
    { name: '月', value: TimePlanRuleType.MONTH },
    { name: '季', value: TimePlanRuleType.SEASON }
];

export class TimeTaskEntityModel extends BaseEntityModel {
    constructor() {
        super();
        this.planTime = [moment()];
    }
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '周期单位',
        type: FormControlType.RADIO_GROUP,
        options: TIME_PLAN_RULE_TYPE_OPTIONS,
        required: true
    } as FormControlOptionModel)
    cycleUnit: TimePlanRuleType = TimePlanRuleType.DAY;

    @FormControl({
        label: '计划时间',
        type: FormControlType.TIME_PICKERS,
        required: true,
        format: 'HH:mm:ss'
    } as FormControlTimePickerModel)
    planTime: Array<Moment> = null;

    @FormControl({
        label: '提前时间',
        type: FormControlType.NUMBER,
        step: 1,
        noMessageTooltip: true,
        message: '分',
        placeholder: '指定时间，自动创建任务',
        required: true,
        invisibleFunction: (model: TimeTaskEntityModel) => { return model.cycleUnit === TimePlanRuleType.MONTH; }
    } as FormControlTextModel)
    aheadTime: number = undefined;

    @FormControl({
        label: '间隔天数',
        type: FormControlType.NUMBER,
        step: 1,
        required: true,
        invisibleFunction: (model: TimeTaskEntityModel) => { return model.cycleUnit !== TimePlanRuleType.DAY; }
    } as FormControlTextModel)
    dayRule: number = undefined;

    @FormControl({
        label: '周规则',
        type: FormControlType.CHECKBOX_GROUP,
        options: [
            { name: '周一', value: '1' },
            { name: '周二', value: '2' },
            { name: '周三', value: '3' },
            { name: '周四', value: '4' },
            { name: '周五', value: '5' },
            { name: '周六', value: '6' },
            { name: '周日', value: '7' }
        ],
        required: true,
        layout: { labelCol: 5, wrapperCol: 18, width: '100%' },
        invisibleFunction: (model: TimeTaskEntityModel) => { return model.cycleUnit !== TimePlanRuleType.WEEK; }
    } as FormControlOptionModel)
    weekRule: Array<string> = undefined;

    @FormControl({
        label: '月规则',
        type: FormControlType.MONTH_SEASON_RULE,
        required: true,
        invisibleFunction: (model: TimeTaskEntityModel) => { return model.cycleUnit !== TimePlanRuleType.MONTH; }
    } as FormControlMonthSeasonModel)
    monthRule: { type: string, day?: string } = { type: JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG };

    @FormControl({
        label: '季规则',
        type: FormControlType.MONTH_SEASON_RULE,
        ruleType: TimePlanRuleType.SEASON,
        required: true,
        invisibleFunction: (model: TimeTaskEntityModel) => { return model.cycleUnit !== TimePlanRuleType.SEASON; }
    } as FormControlMonthSeasonModel)
    seasonRule: { type: string, day?: string, month?: string } = { type: JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG };

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    describe: string = undefined;

    createrName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '描述',
                dataIndex: 'describe'
            },
            {
                title: '周期单位',
                dataIndex: 'cycleUnit',
                customRender: (text, record, index) => {
                    return _.get(_.find(TIME_PLAN_RULE_TYPE_OPTIONS, item => item.value === text), 'name');
                }
            },
            {
                title: '创建人',
                dataIndex: 'createrName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                scopedSlots: { customRender: 'createdTime' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (!json) {
            return this;
        }
        if (json.ruleEntities) {
            this.planTime = _.map(json.ruleEntities, item => moment(_.get(item, 'planTime'), 'HH:mm:ss'));
        }
        switch (json.cycleUnit) {
            case TimePlanRuleType.DAY:
                this.dayRule = _.get(json, 'ruleEntities[0].rule');
                break;
            case TimePlanRuleType.WEEK:
                this.weekRule = _.get(json, 'ruleEntities[0].rule')?.split(',');
                break;
            case TimePlanRuleType.MONTH:
                {
                    const dayRule = _.get(json, 'ruleEntities[0].rule');
                    if (dayRule === JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG) {
                        this.monthRule = {
                            type: JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG
                        };
                    } else {
                        this.monthRule = {
                            type: JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG,
                            day: dayRule
                        };
                    }
                }
                break;
            case TimePlanRuleType.SEASON:
                {
                    const dayRule = _.get(json, 'ruleEntities[0].rule');
                    if (dayRule === JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG) {
                        this.seasonRule = {
                            type: JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG,
                            month: _.get(json, 'ruleEntities[0].monthOfYear') + ''
                        };
                    } else {
                        this.seasonRule = {
                            type: JTL.CONSTANT.CUSTOM_DAY_OF_MONTH_FLAG,
                            day: dayRule,
                            month: _.get(json, 'ruleEntities[0].monthOfYear') + ''
                        };
                    }
                }
                break;
        }
        return this;
    }

    toService() {
        const data: any = super.toService();
        switch (this.cycleUnit) {
            case TimePlanRuleType.DAY:
                data.rule = this.dayRule;
                break;
            case TimePlanRuleType.WEEK:
                data.rule = this.weekRule.join(',');
                break;
            case TimePlanRuleType.MONTH:
                data.rule = this.monthRule.type === JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG ? JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG : _.get(this.monthRule, 'day');
                data.aheadTime = 0;
                break;
            case TimePlanRuleType.SEASON:
                data.rule = this.seasonRule.type === JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG ? JTL.CONSTANT.LAST_DAY_OF_MONTH_FLAG : _.get(this.seasonRule, 'day');
                data.monthOfYear = _.get(this.seasonRule, 'month');
                data.aheadTime = 0;
                break;
        }
        data.planTime = _.map(this.planTime, item => item.format('HH:mm:ss'));
        delete data.dayRule;
        delete data.weekRule;
        delete data.monthRule;
        delete data.seasonRule;
        return data;
    }
}

export class TimeTaskQueryModel extends QueryPageModel {
    name: string = undefined;

    toService() {
        return {
            params: {
                name: this.name
            }
        };
    }
}
