
import { TimeTaskEntityModel, TimeTaskQueryModel } from '@/entity-model/time-task-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/timePlan`;

class TimeTaskService implements ICRUDQ<TimeTaskEntityModel, TimeTaskQueryModel> {
    async create(model: TimeTaskEntityModel):Promise<TimeTaskEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<TimeTaskEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new TimeTaskEntityModel().toModel(res);
    }

    async update(model: TimeTaskEntityModel):Promise<TimeTaskEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: TimeTaskEntityModel):Promise<TimeTaskEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: TimeTaskQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new TimeTaskEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new TimeTaskEntityModel().toModel(item));
    }
}

export default new TimeTaskService();
